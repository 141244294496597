<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <CIcon name="cil-contact"/> Información Fiscal
        <div class="card-header-actions">
          <CButton v-show="!openForm" color="info" size="sm" @click="enableForm()">Editar</CButton>
        </div>
      </slot>
    </CCardHeader>
    <CCardBody>
      <CForm>
        <CRow>
          <CCol md="6">
            <CSelect
                label="Tipo de Contribuyente:"
                :options="typeOptions"
                :value.sync="$v.form.fiscal_type.$model"
                :isValid="checkIfValid('fiscal_type')"
                placeholder=""
                invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
                :readonly="!openForm"
                @change="changeType()"
              />
          </CCol>
          <CCol md="6">
            <CSelect
                label="Régimen Fiscal:"
                :options="regimensOptions"
                :value.sync="$v.form.regimen.$model"
                :isValid="checkIfValid('regimen')"
                placeholder=""
                invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
                :readonly="!openForm"
              />
          </CCol>
        </CRow>
        <CRow>
          <CCol md="6">
            <CInput
              label="RFC:"
              :lazy="false"
              :value.sync="$v.form.rfc.$model"
              :isValid="checkIfValid('rfc')"
              placeholder=""
              autocomplete="rfc"
              invalidFeedback="Este campo es requerido y debe tener 12 o 13 caracteres."
              :readonly="!openForm"
            />
          </CCol>
          <CCol md="6">
            <CInput
              label="Razón Social:"
              :lazy="false"
              :value.sync="$v.form.fiscal_name.$model"
              :isValid="checkIfValid('fiscal_name')"
              placeholder=""
              autocomplete="fiscal_name"
              invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
              :readonly="!openForm"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol md="6">
            <CInput
              label="Calle:"
              :lazy="false"
              :value.sync="$v.form.street.$model"
              :isValid="checkIfValid('street')"
              placeholder=""
              autocomplete="street"
              invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
              :readonly="!openForm"
            />
          </CCol>
          <CCol md="3">
            <CInput
              label="No. Exterior:"
              :lazy="false"
              :value.sync="$v.form.exterior.$model"
              :isValid="checkIfValid('exterior')"
              placeholder=""
              autocomplete="exterior"
              invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
              :readonly="!openForm"
            />
          </CCol>
          <CCol md="3">
            <CInput
              label="No. Interior: "
              :lazy="false"
              :value.sync="$v.form.interior.$model"
              :isValid="checkIfValid('interior')"
              placeholder=""
              autocomplete="interior"
              invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
              :readonly="!openForm"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol md="4">
            <CInput
              label="Colonia:"
              :lazy="false"
              :value.sync="$v.form.colony.$model"
              :isValid="checkIfValid('colony')"
              placeholder=""
              autocomplete="colony"
              invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
              :readonly="!openForm"
            />
          </CCol>
          <CCol md="4">
            <CInput
              label="Localidad:"
              :lazy="false"
              :value.sync="$v.form.locality.$model"
              :isValid="checkIfValid('locality')"
              placeholder=""
              autocomplete="locality"
              invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
              :readonly="!openForm"
            />
          </CCol>
          <CCol md="4">
            <CInput
              label="Municipio: "
              :lazy="false"
              :value.sync="$v.form.municipality.$model"
              :isValid="checkIfValid('municipality')"
              placeholder=""
              autocomplete="municipality"
              invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
              :readonly="!openForm"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol md="4">
            <CSelect
              label="Estado: "
              :options="statesOptions"
              :value.sync="$v.form.state.$model"
              :isValid="checkIfValid('state')"
              placeholder=""
              invalidFeedback="Este campo es requerido."
              :readonly="!openForm"
            />
          </CCol>
          <CCol md="4">
            <CSelect
                label="País: "
                :options="countriesOptions"
                :value.sync="$v.form.country.$model"
                :isValid="checkIfValid('country')"
                placeholder=""
                invalidFeedback="Este campo es requerido."
                :readonly="!openForm"
              />
          </CCol>
          <CCol md="4">
            <CInput
              label="Código Postal: "
              :lazy="false"
              :value.sync="$v.form.zipcode.$model"
              :isValid="checkIfValid('zipcode')"
              placeholder=""
              autocomplete="zipcode"
              invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
              :readonly="!openForm" 
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol md="12">
            <CButton v-show="openForm" @click="closeForm" color="secondary">Cancelar</CButton>
            <CButton v-show="openForm" @click="storeForm" color="info" style="float: right;">Guardar</CButton>
          </CCol>
        </CRow>
      </CForm>
    </CCardBody>
  </CCard>
</template>

<script>
import ws from '../../services/products';
import store from '../../store'
import { validationMixin } from "vuelidate"
import { required, minLength, maxLength, integer, email} from "vuelidate/lib/validators"
import account_ws from '../../services/account';
import general_ws from '../../services/general';

export default {
  name: 'MyAccountFiscalForm',
  components: { },
  props: {
    
  },
  data() {
    return {
      openForm: false,
      isEdit: false,
      form: this.getEmptyForm(),
      submitted: false,
      typeOptions: [
        { value: 'physical', label: 'Persona Física' },
        { value: 'moral', label: 'Persona Moral' }
      ],
      states: [],
      statesOptions: [],
      countries: [],
      countriesOptions: [],
      physical_regimens: [],
      moral_regimens: [],
      regimensOptions: [],
    }
  },
  mounted: async function() {
    this.loading();
    let response = await account_ws.getInfo(); 

    if(response.type == "success"){
      this.form.contact_name = response.data.name;
      this.form.email = response.data.email;
      this.form.phone = response.data.phone;
      this.form.fiscal_type = response.data.fiscal_type;
      this.form.huso_horario = response.data.huso_horario;
    }

    let response1 = await account_ws.getFiscalInfo(); 

    if(response1.type == "success"){
      this.form.rfc = response1.data.rfc;
      this.form.comercial_name = response1.data.comercial_name;
      this.form.fiscal_name = response1.data.fiscal_name;
      
      this.form.street = response1.data.street;
      this.form.exterior = response1.data.exterior;
      this.form.interior = response1.data.interior;
      this.form.colony = response1.data.colony;
      this.form.locality = response1.data.locality;
      this.form.municipality = response1.data.municipality;
      this.form.state = response1.data.state;
      this.form.country = response1.data.country;
      this.form.zipcode = response1.data.zipcode;

      this.form.regimen = response1.data.regimen.code;
    }

    let response2 = await general_ws.getStates(); 

    if(response2.type == "success"){
      this.states = response2.data;
    }

    let response3 = await general_ws.getCountries(); 

    if(response3.type == "success"){
      this.countries = response3.data;
    }

    let response4 = await general_ws.getPhysicalRegimens(); 

    if(response4.type == "success"){
      this.physical_regimens = response4.data;
    }

    let response5 = await general_ws.getMoralRegimens(); 

    if(response5.type == "success"){
      this.moral_regimens = response5.data;
    }

    if(this.form.fiscal_type == "physical"){
      this.regimensOptions = await this.$parseSelectOptionsOrdered(this.physical_regimens, "code", "code:name");
    }
    else{
      this.regimensOptions = await this.$parseSelectOptionsOrdered(this.moral_regimens, "code", "code:name");
    }

    this.statesOptions = await this.$parseSelectOptionsOrdered(this.states, "code", "name");
    this.countriesOptions = await this.$parseSelectOptionsOrdered(this.countries, "code", "name");

    this.form.country = "MEX";

    this.loaded();
  },
  methods: {
    async changeType(){
      if(this.form.fiscal_type == "physical"){
        this.regimensOptions = await this.$parseSelectOptionsOrdered(this.physical_regimens, "code", "code:name");
      }
      else{
        this.regimensOptions = await this.$parseSelectOptionsOrdered(this.moral_regimens, "code", "code:name");
      }
    },
    enableForm () {
      this.openForm = true;
      this.submitted = false;

      this.isEdit = true;
      this.submitted = false;
    },
    async storeForm () {
      this.$v.$touch();

      if (this.isValid) {
        this.loading();
        this.submitted = true;

        let response = await account_ws.setFiscalInfo(this.form);

        if(response.type == "error"){
          let response1 = await account_ws.getFiscalInfo(); 

          if(response1.type == "success"){
            this.form.rfc = response1.data.rfc;
          }
        }

        this.loaded();
        this.showToast(response.type, response.message);

        this.closeForm();
      }
    },
    closeForm () {
      this.openForm = false;
      this.submitted = false;

      this.$v.$reset();
    },
    getEmptyForm () {
      return {
          id: 0,
          contact_name: '',
          email: '',
          phone: '',
          fiscal_type: '',
          huso_horario: '',
          rfc: '',
          comercial_name: '',
          fiscal_name: '',
          street: '',
          exterior: '',
          interior: '',
          colony: '',
          locality: '',
          municipality: '',
          state: '',
          country: '',
          zipcode: '',
          regimen: ''
      }
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid)
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    },
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      fiscal_type: {},
      regimen: {},
      rfc: {
        required,
        minLength: minLength(12),
        maxLength: maxLength(13)
      },
      fiscal_name: {
        required,
        minLength: minLength(3)
      },
      street: {},
      exterior: {},
      interior: {},
      colony: {},
      locality: {},
      municipality: {},
      state: {},
      country: {},
      zipcode: {}
    }
  },
}
</script>
